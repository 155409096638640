import { Component, Vue } from 'vue-property-decorator';

import links from '@/services/links'

import SocialIcons from '@/components/socialIcons/socialIcons.vue'
import SolicitacaoIcons from '@/components/solicitacaoIcons/solicitacaoIcons.vue';

import {
  Icons,
} from '@/components/base/index';

@Component({
  components: {
    Icons,
    SocialIcons,
    SolicitacaoIcons,
  },
})
export default class NavBar extends Vue{
  isHoverable = true;
  isArrowless = false;

  screen_width = screen.width; 

  externalLink = links.mapLinksExternos

  goTo(link: string): void{
    console.log(link);
    console.log(link.split(':'));
    if( link.split(':').length >= 2 ){
      window.location.href = link;
      return;
    }
    this.$router.push(link);
  }

  arrNavConfig = [
    {
      title: 'Prefeitura',
      isDropdown: true,
      list: links.getPrefeituraPages()
    },
    {
      title: 'Atos oficiais',
      isDropdown: true,
      list: links.getAtosOficiaisPages()
    },
    {
      title: 'Secretarias',
      isDropdown: true,
      list: links.getSecretariasPages()
    },
    {
      title: 'Cidadão',
      isDropdown: true,
      list: links.getCidadaoPages()
    },
    {
      title: 'Turismo',
      isDropdown: true,
      list: links.getTurismoPages()
    },
    {
      title: 'Servidor',
      isDropdown: true,
      list: links.getServidorPages()
    },
    {
      title: 'Legislação',
      isDropdown: true,
      list: links.getLegislacaoPages()
    },
    {
      title: 'Transparencia',
      isDropdown: true,
      list: links.getTransparenciaPages()
    },
    // {
    //   title: 'Atos oficiais',
    //   link: 'https://materialdesignicons.com/',
    //   isExternalLink: true,  
    //   isDropdown: true,
    // }
  ]
}
